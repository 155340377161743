/**
 * axios の AxiosURLSearchParams に似たコードあり
 */
const encode = (str: Parameters<typeof encodeURIComponent>[0]) =>
  encodeURIComponent(str).replace(
    /[!'()~]|%20|%00/g,
    (match) =>
      (
        ({
          "!": "%21",
          "'": "%27",
          "(": "%28",
          ")": "%29",
          "~": "%7E",
          "%20": "+",
          "%00": "\x00",
        }) as Record<string, string>
      )[match] ?? "",
  );

/**
 * @deprecated
 * 互換性のため一旦残しているが、廃止予定
 * @note
 * src/api/generated/aspida/$api.ts に import される。
 * $path メソッドで option.query オブジェクトを 文字列化していた。
 * cf. aspida/packages/aspida/src/createMethodsString.ts
 * コピー元実装: https://github.com/aspida/aspida/blob/e907f191f45fec3b542744a01179ebf5aa76fdc4/packages/aspida/src/index.ts#L82~L90
 */
export const dataToURLString = (data: Record<string, any>) =>
  Object.keys(data)
    .filter((key) => data[key] != null)
    .map((key) =>
      Array.isArray(data[key])
        ? data[key].map((v: string) => `${encode(key)}=${encode(v)}`).join("&") //
        : `${encode(key)}=${encode(data[key])}`,
    )
    .join("&");
