/* eslint-disable import/prefer-default-export */
import { type URLSearchParams as URLSearchParamsType } from "url";
import { convertDotToBracket } from "./url-helper";
import { getNameOfItemByUUID } from "@/helper/uuid-object";

export function buildFormData(formData: FormData | undefined): FormData {
  const data = new FormData();

  formData?.forEach((value: any, key) => {
    const names = key.split(".");
    const name =
      names[0] +
      `[${names.slice(1).join("][")}]`
        .replace("[]", "")
        .replace(/\[\*\]/, "[]");

    data.append(name, value);
  });

  return data;
}

export const formDataAccessor = (formData: FormData | undefined) => {
  return (name: string): string | null => formData?.get(name) as string | null;
};

export const leastOneValidate = (
  parentFieldName: string,
  getValues: (fieldName?: string) => any,
  filterCondition: (value: any) => boolean,
): ((value: any) => boolean) => {
  return (_v: any) => {
    const values = getValues()[parentFieldName];
    return values?.filter(filterCondition).length >= 1;
  };
};

export const buildQueryString = (
  formData: FormData | URLSearchParams | undefined,
): URLSearchParamsType => {
  const queryString = new URLSearchParams();
  formData?.forEach((value, key) => {
    if (value === "") return;
    // Append to the query string
    queryString.append(convertDotToBracket(key), value as string);
  });
  return queryString;
};

export const getFormData = (object: Record<string, any>): FormData =>
  Object.keys(object).reduce((formData, key) => {
    formData.append(key, object[key]);
    return formData;
  }, new FormData());

export type WithIdAndUUID<T> = T & { id?: number; uuid: string };

type UUIDAndValue = { value: string; uuid: string };

interface CompareTarget {
  prev: UUIDAndValue;
  next: UUIDAndValue;
  label: string;
}

type ShouldCompare = (compareTarget: CompareTarget) => boolean;

export type AllowConfirmType = {
  prev: UUIDAndValue;
  getNext: (data: FormData) => UUIDAndValue;
  label: string;
  shouldCompare: ShouldCompare;
};

export const makeConfirmationPair = <
  T extends { uuid?: string; name?: string },
>(
  label: string,
  uuidFormName: string,
  prevUuid: string | undefined,
  items: T[],
  shouldCompare: ShouldCompare = () => true,
): AllowConfirmType => ({
  prev: {
    uuid: prevUuid ?? "",
    value: getNameOfItemByUUID(items, prevUuid, ""),
  },
  getNext: (formData) => {
    const uuid = String(formData.get(uuidFormName));
    return { uuid, value: getNameOfItemByUUID(items, uuid, "") };
  },
  label,
  shouldCompare,
});

export const computeDifferentChanges = <T>(
  allowConfirm: AllowConfirmType[],
  formData: FormData,
): CompareTarget[] =>
  allowConfirm
    .map(({ prev, getNext, label, shouldCompare }) => ({
      prev,
      next: getNext(formData),
      label,
      shouldCompare,
    }))
    .filter(({ prev, next, label, shouldCompare }) => {
      const isDifferent = prev.uuid !== next.uuid;
      const willCompare = shouldCompare({ prev, next, label });
      return isDifferent && willCompare;
    });
