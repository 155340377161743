import {
  type FC,
  type PropsWithChildren,
  createContext,
  useContext,
  useState,
  useMemo,
} from "react";
import { localStorageKeys } from "@/configs/localStorage";
import { localStorageAvailable } from "@/helper/local-storage";

/**
 * 初期値はtrueの理由
 * https://github.com/torana-us/madras/issues/239
 */
const initialValue = true;
const _useCommentDisclosure = () => {
  const [isCommentOpen, setComment] = useState(() => {
    if (!localStorageAvailable()) {
      return initialValue;
    }

    const boolString =
      localStorage.getItem(localStorageKeys.comment) ?? String(initialValue);
    return boolString === "true";
  });

  const toggleCommentArea = () => {
    const setItem = !isCommentOpen;
    if (localStorageAvailable()) {
      localStorage.setItem(localStorageKeys.comment, String(setItem));
    }
    setComment(setItem);
  };
  return { isCommentOpen, toggleCommentArea } as const;
};

const CommentContext = createContext<ReturnType<typeof _useCommentDisclosure>>(
  {} as any,
);

export const useCommentDisclosure = () => {
  return useContext(CommentContext);
};

export const CommentToggleProvider: FC<PropsWithChildren> = ({ children }) => {
  const comment = _useCommentDisclosure();
  const memorizedComment = useMemo(() => comment, [comment]);
  return (
    <CommentContext.Provider value={memorizedComment}>
      {children}
    </CommentContext.Provider>
  );
};
