export const madrasEnv = () =>
  process.env.MADRAS_ENV as
    | "production"
    | "staging"
    | "qa"
    | "development"
    | "test";
export const isStg = (): boolean => {
  return madrasEnv() === "staging";
};

export const isTest = (): boolean => {
  return madrasEnv() === "test" && process.env.NODE_ENV === "test";
};
export const isDevelopment = (): boolean => {
  return madrasEnv() === "development";
};

export const isPrd = (): boolean => {
  return madrasEnv() === "production";
};

export const isPreStg = (): boolean => {
  // eslint-disable-next-line no-restricted-globals
  return location.hostname === "local.madras.im";
};

export const isQa = (): boolean => {
  return madrasEnv() === "qa";
};

export const isNotProduction = (): boolean => {
  return isStg() || isDevelopment() || isQa();
};

export const isDebug = (): boolean => {
  return isDevelopment() && process.env.DEBUG_ON === "1";
};

export const kenAllApiKey = process.env.KENALL_API_KEY;

export const version = process.env.APP_VERSION_HASH;

export const service = process.env.SERVICE_NAME;
