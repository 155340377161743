import { type HTMLAttributes, useEffect, useState } from "react";
import { useSWRConfig } from "swr";
import { type AutocompleteRenderOptionState } from "@mui/material/Autocomplete";
import { css } from "@emotion/react";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import { type RhfComboboxProps } from "../RhfCombobox";
import { type OptionValue } from "../types";
import { useDebounce } from "@/hooks/useDebounce";
import { useDisclosure } from "@/hooks/useDisclosure";
import { identity } from "@/helper/function";
import { MAX_LIMIT } from "@/features/helper/allFetchable";

export const CUSTOM_HOOK_KEY = "useOptionsResultToProps";
export const MSG_FOR_MAX_LIMIT =
  "データ件数が多いため全ての候補を表示できません。入力することで候補が絞り込まれます。";

export const createRenderOption = <T extends OptionValue>(options: T[]) =>
  function renderOption(
    props: HTMLAttributes<HTMLLIElement> & { key?: string },
    option: T,
    state: AutocompleteRenderOptionState,
  ) {
    // データ件数が MAX_LIMIT 以上である場合、オプションリストの先頭に警告メッセージを表示
    if (options.length >= MAX_LIMIT && state.index === 0) {
      return (
        <>
          <Box key="message" p={1} css={css``}>
            {MSG_FOR_MAX_LIMIT}
          </Box>
          <Divider />
          <li {...props}>{option.name}</li>
        </>
      );
    }
    return <li {...props}>{option.name}</li>;
  };

/**
 * Combobox と組み合わせて、入力データに応じて options を非同期で取得するためのロジックをまとめるカスタムフック。
 * @param baseKey options を取得するために swr で用いる key の一部であり、リクエストを区別・特定するために用いる。
 * @param options.delay 入力待ち時間（ms）
 * @returns
 * * swrOptions: swr に渡すオプション
 * * getComboboxProps: Combobox の props を制御する関数
 * * debouncedInputValue: 検索リクエストに利用される想定の debounce された入力値
 */
export const useDynamicRequest = (
  baseKey: string,
  fieldValue: { name: string } | null,
  option?: { delay?: number },
) => {
  const customHookKey = `${baseKey}:${CUSTOM_HOOK_KEY}`;

  const { isOpen, open, close } = useDisclosure();
  const [inputValue, setInputValue] = useState("");
  const { debouncedValue: debouncedInputValue, isDebouncing } = useDebounce(
    inputValue,
    { delay: option?.delay, disable: inputValue === "" },
  );

  const swrOptions = {
    key: (() => {
      // 選択済みデータを検索するような無駄なリクエストをしないようにする
      if (fieldValue?.name === debouncedInputValue) {
        return null;
      }
      return `${customHookKey}${debouncedInputValue}`;
    })(),
  };

  const { cache } = useSWRConfig();
  useEffect(() => {
    // 入力に応じてリクエストが複数発生し、その都度データを cache すると、cache が増えすぎてしまう恐れがあるため、
    // 入力に応じて発生するリクエストの cache は削除する。
    // なお、SWRにキャッシュ無効化機能は、実装時点では存在しないと思われる。（cf. https://github.com/vercel/swr/discussions/456#discussioncomment-986323）
    const customHookRegExp = new RegExp(`${customHookKey}.+`);
    [...cache.keys()].forEach((key) => {
      if (customHookRegExp.test(key)) {
        cache.delete(key);
      }
    });
  }, [cache, customHookKey, swrOptions.key]);

  const onInputChange: RhfComboboxProps["onInputChange"] = (e, value) => {
    setInputValue(value);
  };

  const onOpen = () => {
    setInputValue(""); // input value が残っていると、options が input value のデータに限定されてしまうため、入力をクリア
    open();
  };

  const basicProps = {
    open: isOpen,
    onOpen,
    onClose: close,
    inputValue,
    onInputChange,
    filterOptions: identity, // デフォルトのフィルタリングを無効化
  };

  return {
    swrOptions,
    /**
     * Combobox の props を制御する関数
     * @param args.loading api でデータを取得している最中である場合 true
     * @param args.options api で取得した、オプションとして表示されるデータ
     * @returns
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- FIXME
    getComboboxProps: (args: { loading: boolean; options?: any }) => {
      const options = args.options ?? [];
      return {
        ...basicProps,
        loading: isDebouncing || args.loading,
        options,
        renderOption: createRenderOption(options),
      };
    },
    debouncedInputValue,
  };
};
