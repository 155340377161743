/* eslint-disable import/prefer-default-export */

export const replaceToBracketsWith =
  (regex: RegExp) =>
  (path: string): string => {
    const go = (p: string): string =>
      regex.test(p) ? go(p.replace(regex, "[$1]")) : p;

    return go(path);
  };

export const dotNumToBracketsNum = replaceToBracketsWith(/\.(\d+)/);

export const dotWardToBracketsWord = replaceToBracketsWith(/\.(\w+)/);

export const replaceIndexToZero = (path: string): string => {
  const p0 = path.replace(/^\d+/, "0");
  const p1 = p0.replace(/\.\d+/g, ".0");
  const p2 = p1.replace(/\[\d+\]/g, ".0");
  const p3 = p2.replace(/^\.0/, "0");

  return p3;
};

// PHP の mb_strwidth と同様の動作をする
// http://php.net/manual/ja/function.mb-strwidth.php
export const mbStrLength = (str: string | undefined = ""): number => {
  let i = 0;
  const l = str.length;
  let c;
  let length = 0;
  for (; i < l; i += 1) {
    c = str.charCodeAt(i);
    if (c >= 0x0000 && c <= 0x0019) {
      length += 0;
    } else if (c >= 0x0020 && c <= 0x1fff) {
      length += 1;
    } else if (c >= 0x2000 && c <= 0xff60) {
      length += 2;
    } else if (c >= 0xff61 && c <= 0xff9f) {
      length += 1;
    } else if (c >= 0xffa0) {
      length += 2;
    }
  }
  return length;
};

export const isFalsyString = (
  str: string | undefined | null,
): str is "" | undefined | null =>
  str === "" || str === undefined || str === null;

export const isNotFalsyString = (
  str: string | undefined | null,
): str is string => !isFalsyString(str);

export const replaceIfFalsyString = ({
  original,
  replace,
}: {
  original: string | undefined;
  replace: string;
}): string => (isFalsyString(original) ? replace : (original as string));
