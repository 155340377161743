/* eslint-disable import/prefer-default-export */
/* eslint-disable no-continue */
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */

import { isInteger, isObject } from "./predicate";

export function flattenObject(
  o: any,
  depth: number | undefined = undefined,
  join = (arr: string[]) => arr.join("."),
): any {
  const newObj: Record<string, any> = {};
  for (const k in o) {
    const joinedNames = [k];
    if (!Array.isArray(o) && typeof o[k] === "object") {
      if (depth === 0) {
        return undefined;
      }
      const deeplyObject = flattenObject(
        o[k],
        depth !== undefined ? depth - 1 : undefined,
        join,
      );
      if (deeplyObject === undefined) {
        continue;
      }
      if (Object.keys(deeplyObject).length > 0) {
        for (const k2 in deeplyObject) {
          newObj[join([...joinedNames, k2])] = deeplyObject[k2];
        }
        continue;
      }
    } else if (Array.isArray(o)) {
      if (depth === 0) {
        return undefined;
      }
      for (const m of o) {
        const fm = flattenObject(
          m,
          depth !== undefined ? depth - 1 : undefined,
          join,
        );
        if (fm === undefined) {
          continue;
        }
        for (const n of Object.keys(fm)) {
          newObj[join(joinedNames.concat([n]))] = fm[n];
        }
      }
      continue;
    }
    newObj[join(joinedNames)] = o[k];
  }
  return newObj;
}

const getPropsName = (parent: string | undefined, key: string): string => {
  if (parent === undefined) return key;

  // 正規表現で'[]'を判定に用いているコードがあるため現時点では'.'に統一できません
  if (isInteger(key)) return `${parent}[${key}]`;

  return `${parent}.${key}`;
};

type Data = number | string;
type NestedData = Record<string, any>;
type SingleDepthObject = { [key: string]: Data };

/**
 * TODO: 下記 issue close 後、 flattenObject 利用箇所を調査し、toSingleDepthObject に統一できないか検討する
 * issue: https://github.com/torana-us/madras-crm-frontend/issues/1488
 */
export const toSingleDepthObject = (
  obj: NestedData,
  parent?: string,
  res: SingleDepthObject = {},
) => {
  for (const key of Object.keys(obj)) {
    const propName = getPropsName(parent, key);
    const value = obj[key];
    if (Array.isArray(value) && value.length === 0) {
      res[propName] = "[]";
    } else if (isObject(value)) {
      toSingleDepthObject(value, propName, res);
    } else {
      // convert null or undefined to empty string
      res[propName] = (value ?? "") as Data;
    }
  }
  return res;
};
