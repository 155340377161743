import { type ScreenMode } from "@/types";

export interface ScreenModes {
  isCreateMode: boolean;
  isShowMode: boolean;
}
/* eslint-disable import/prefer-default-export */
export const screenModes = (mode: ScreenMode | undefined): ScreenModes => ({
  isCreateMode: mode === "create",
  isShowMode: mode === "show",
});
