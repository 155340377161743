import { getUrl } from "@/api/helpers";
import { type PartialParams } from "@/api/helpers/api-client-wrapper";
import { type ApiPathsWithGetJsonMethod, type Methods } from "@/api/types";
import { type ValueKey } from "@/api/types/AspidaSWROption";
import { propOrUndefined } from "@/helper";
import { dataToURLString } from "@/helper/object/dataToURLString";

export function createKey<
  Path extends ApiPathsWithGetJsonMethod,
  Method extends Methods<Path> & "get",
>({
  path,
  params,
  key: optionalKey,
  enabled,
}: {
  path: Path;
  key?: ValueKey;
  enabled?: boolean;
} & PartialParams<Path, Method>) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- fixme
  const url = getUrl(path as any, propOrUndefined(params, "path") as any);
  if (url === null) {
    return null;
  }

  const queryString =
    params?.query !== undefined ? dataToURLString(params?.query) : "";

  const key = (() => {
    if (optionalKey !== undefined) {
      return optionalKey;
    }
    if (enabled === false) {
      return null; // disabled
    }
    return queryString === "" ? url : `${url}?${queryString}`;
  })();

  return key;
}
