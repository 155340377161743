/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-continue */
/* eslint-disable no-restricted-syntax */
import styled from "@emotion/styled";
import { type FC, type ReactElement, type ReactNode } from "react";
import { useSelector } from "react-redux";
import { type Wait } from "@/types/Wait";
import { type RootState } from "@/modules/store";

export const testIdLoading = "components/fragments/Loading";
export const testIdLoaderDefinition =
  "components/fragments/Loading-LoaderDefinition";
const LoadingScreen = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1500;
`;

const SimpleLoadingScreen = styled.div`
  text-align: center;
  margin: 24px 0;
`;

export const LoadingWith: FC<
  React.PropsWithChildren<{
    defaultValue: React.ReactNode;
    ids: Wait[];
    loadingDefaultValue?: boolean;
    children: ReactNode;
  }>
> = (props) => {
  const { children, ids, loadingDefaultValue } = props;
  const isLoading = useSelector((reducer: RootState) => {
    const result = [];
    for (const k of ids.filter((id) => id !== undefined)) {
      if (k === undefined) {
        continue;
      }
      if (typeof k !== "string") {
        result.push(reducer.loading[`${k.method.toUpperCase()}:${k.url}`]);
      } else {
        result.push(reducer.loading[`GET:${k}`]);
        result.push(reducer.loading[`POST:${k}`]);
        result.push(reducer.loading[`PUT:${k}`]);
        result.push(reducer.loading[`PATCH:${k}`]);
        result.push(reducer.loading[`DELETE:${k}`]);
      }
    }
    return result.filter((data) => data !== undefined).length > 0;
  });

  if (isLoading || (loadingDefaultValue !== undefined && loadingDefaultValue)) {
    return props.defaultValue as ReactElement;
  }

  return children as ReactElement;
};

export type ContentLoaderType = "pulse" | "circle";

export const LoaderDefinition: FC<
  React.PropsWithChildren<{ type: ContentLoaderType }>
> = ({ type }) => {
  return (
    <SimpleLoadingScreen data-testid={testIdLoaderDefinition}>
      {type === "pulse" && <img src="/images/loading-pulse.svg" width="40" />}
      {type === "circle" && <img src="/images/loading.gif" width="32" />}
    </SimpleLoadingScreen>
  );
};

export const LocalLoading: FC<
  React.PropsWithChildren<{
    enable: boolean;
    type: ContentLoaderType;
    children: ReactNode;
  }>
> = (props) => {
  const { enable, type, children } = props;
  if (enable) {
    return <LoaderDefinition type={type} />;
  }
  return children as ReactElement;
};

export const SimpleLoading: FC<
  React.PropsWithChildren<{
    children: ReactNode;
    ids: Wait[];
    type?: ContentLoaderType;
    loadingDefaultValue?: boolean;
  }>
> = ({ children, ids, type = "pulse", loadingDefaultValue }) => {
  return (
    <LoadingWith
      ids={ids}
      defaultValue={<LoaderDefinition type={type} />}
      loadingDefaultValue={loadingDefaultValue}
    >
      {children}
    </LoadingWith>
  );
};

export const Loading: FC<{ type?: ContentLoaderType }> = ({ type }) => {
  return (
    <LoadingScreen data-testid={testIdLoading}>
      {type === undefined ? (
        <img src="/images/loading.gif" width="64" />
      ) : (
        <LoaderDefinition type={type} />
      )}
    </LoadingScreen>
  );
};
