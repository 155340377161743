import { isBusinessLogic } from "./is-business-logic";
import { type APIErrorType } from "../types";
import { getCodesOrCodeArray } from "../get-codes";

/**
 * error code が含まれる response body であり、かつ、
 * 含まれる error code が全て business logic code である場合、 true を返却する。
 */

export const isBusinessLogicErrorResponse = (responseData: unknown) => {
  const codesMaybe = getCodesOrCodeArray(responseData);
  if (codesMaybe === undefined) {
    return false;
  }
  if (codesMaybe.length === 0) {
    return false;
  }

  return codesMaybe.every(isBusinessLogic);
};

// eslint-disable-next-line complexity -- 単純な分岐なので許容
export const getErrorType = (
  status: number | undefined,
  responseData: unknown,
): APIErrorType => {
  if (status === undefined) {
    return "deadLogic";
  }
  // ここからはresponseが存在する場合 ≒ サーバー側からのエラーが帰ってきている
  if (status === 400) {
    if (isBusinessLogicErrorResponse(responseData)) {
      return "businessLogic";
    }

    return "badRequest";
  }
  if (status === 401) {
    return "unauthorized";
  }
  if (status === 403) {
    return "forbidden";
  }
  if (status === 404) {
    return "notfound";
  }
  if (status >= 500) {
    return "internal";
  }
  return "deadLogic";
};
