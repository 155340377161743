import { type ReactNode, type FC } from "react";
import { CommentToggleProvider } from "./providers/Comments";
import { FullScreenToggleProvider } from "./providers/FullScreen";
import { MenuToggleProvider } from "./providers/Menu";
import { ExperimentalFeatureToggleProvider } from "./providers/ExperimentalFeature";

/**
 *
 * localStorageだけでは、設定を変更した際に再レンダリングがトリガーされないため、
 * useState x useContext x localStorageで、設定の永続化と再レンダリングをできるようにしています
 */
export const LocalStorageProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  if (typeof window === "undefined") {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  }
  return (
    <CommentToggleProvider>
      <FullScreenToggleProvider>
        <MenuToggleProvider>
          <ExperimentalFeatureToggleProvider>
            {children}
          </ExperimentalFeatureToggleProvider>
        </MenuToggleProvider>
      </FullScreenToggleProvider>
    </CommentToggleProvider>
  );
};
