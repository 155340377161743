import { type FC, type ReactNode, forwardRef } from "react";
import { Global, css } from "@emotion/react";
import {
  type SnackbarKey,
  SnackbarProvider as Provider,
  MaterialDesignContent,
  type CustomContentProps,
} from "notistack";
import { IconButton } from "@mui/material";
import Close from "@mui/icons-material/Close";
import Error from "@mui/icons-material/Error";
import { useSnackbar } from "@/lib/notistack";

interface Props {
  children: ReactNode;
}

const SnackbarCloseButton = ({ snackbarKey }: { snackbarKey: SnackbarKey }) => {
  const { closeSnackbar } = useSnackbar();
  return (
    <IconButton size="small" onClick={() => closeSnackbar(snackbarKey)}>
      <Close />
    </IconButton>
  );
};

const ErrorSnackbar = forwardRef<HTMLDivElement, CustomContentProps>(
  function Content(props, ref) {
    const { message, ...restProps } = props;
    const contentProps = {
      ...restProps,
      autoHideDuration: null,
    };

    return (
      <>
        {/*
          エラー見過ごし防止のため、画面中央にエラーを表示する。
          画面中央にスナックバーを表示するためのプロパティはライブラリ側で用意されていないため、
          CSSで調整する必要があった。
        */}
        <Global
          styles={css`
            div > .notistack-SnackbarContainer {
              top: 50%;
              transform: translate(-50%, -50%);
            }
            .notistack-Snackbar > .notistack-MuiContent-error {
              padding: 1.5rem 1rem;
            }
          `}
        />
        <MaterialDesignContent
          ref={ref}
          {...contentProps}
          iconVariant={{ error: <Error sx={{ mr: "0.7rem" }} /> }}
          message={
            <div
              css={css`
                max-height: 80vh;
                overflow: auto;
                white-space: pre-wrap;
                font-size: 1rem;
              `}
            >
              {message}
            </div>
          }
        />
      </>
    );
  },
);

export const SnackbarProvider: FC<Props> = ({ children }) => {
  return (
    <Provider
      Components={{
        error: ErrorSnackbar,
      }}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      style={{
        flexWrap: "nowrap",
      }}
      autoHideDuration={6000}
      maxSnack={1}
      preventDuplicate
      action={(key) => {
        // 閉じるボタンを有効にするため
        return <SnackbarCloseButton snackbarKey={key} />;
      }}
    >
      {children}
    </Provider>
  );
};
