import { useWatch, type FieldValues, type Path } from "react-hook-form";
import { type SWRResponse } from "swr";
import { type OptionValue } from "./types";
import { type RhfComboboxProps } from "@/components/essentials/autocomplete/RhfCombobox";
import RhfCombobox from "@/components/essentials/autocomplete/RhfComboboxWrapper";
import { useDynamicRequest } from "@/components/essentials/autocomplete/hooks/useDynamicRequest";
import { type DatadogAttributes } from "@/types/datadog";
import { type MadrasAPIPagerReturn } from "@/api/hooks/use-madras-api/type";
import {
  type ApiPathsWithGetJsonMethod,
  type ApiPathsWithoutPathParam,
  type Methods,
  type ApiOption,
} from "@/api/types";
import { useMadrasAPI } from "@/api/hooks";
import { type Pager } from "@/api/swr/types";

type Options<T extends ApiPathsWithoutPathParam & ApiPathsWithGetJsonMethod> =
  RhfComboboxProps<
    FieldValues,
    Path<FieldValues>,
    false,
    MadrasAPIPagerReturn<T, Methods<T> & "get">["data"] extends Array<
      infer S extends OptionValue
    >
      ? S
      : MadrasAPIPagerReturn<T, Methods<T> & "get">["data"] extends {
            items: Array<infer SS extends OptionValue>;
          }
        ? SS
        : never
  >;

type DynamicRequestRhfComboboxProps<
  T extends ApiPathsWithoutPathParam & ApiPathsWithGetJsonMethod,
> = {
  /**
   * `fields[]` search params に "uuid", "name" 以外で追加する値。
   */
  readonly additionalFields?: string[];
  readonly path: T;
  readonly apiOption?: ApiOption;
} & DatadogAttributes &
  Omit<Options<T>, "options">;

export const DynamicRequestRhfCombobox = <
  T extends ApiPathsWithoutPathParam & ApiPathsWithGetJsonMethod,
>({
  path,
  additionalFields = [],
  apiOption,
  ...rest
}: DynamicRequestRhfComboboxProps<T>) => {
  const formValue = useWatch({ name: rest.name, control: rest.control });

  const { getComboboxProps, swrOptions, debouncedInputValue } =
    useDynamicRequest(path, formValue);

  const requestResult = useMadrasAPI(path, {
    $Pager: true,
    key: swrOptions.key,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    params: {} as any, // 本来不要だが、型の制御が難しく妥協してダミーの値をセット
    unsafeQuery: {
      "fields[]": ["uuid", "name", ...additionalFields],
    },
    valueFilter: {
      name: "name",
      value: debouncedInputValue,
    },
    maxLimit: true,
    ...apiOption,
  }) as unknown as SWRResponse<
    Pager<{
      items: Options<T>;
    }>
  >;

  return (
    <RhfCombobox
      {...getComboboxProps({
        loading: requestResult.isLoading,
        options: requestResult.data?.items,
      })}
      {...rest}
    />
  );
};
