import Script from "next/script";
import {
  clientToken,
  site,
  mustBeLogsAndRumSameConfigs,
  applicationId,
  region,
  datadogVersion,
} from "./constants";
import { madrasEnv, version, service } from "../../helper/env";
import { baseURL } from "@/configs/endpoint";
import { isFalsyString } from "@/helper/string";

const env = madrasEnv();

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- browser sdkの型をインストールできないのでany
const datadogLogsInit = (datadogLogs: any): void => {
  if (
    !(env === "production" || env === "staging" || env === "qa") ||
    isFalsyString(clientToken)
  ) {
    return;
  }

  const sampleRate = env === "production" ? 100 : 50;

  // https://docs.datadoghq.com/ja/logs/log_collection/javascript/#%E5%88%9D%E6%9C%9F%E5%8C%96%E3%83%91%E3%83%A9%E3%83%A1%E3%83%BC%E3%82%BF%E3%83%BC
  datadogLogs.init({
    clientToken,
    env,
    version,
    service,
    site,
    forwardErrorsToLogs: false, // キャッチされない例外はerror boundaryでキャッチしてログ送信するので、ここでは送信しない
    forwardConsoleLogs: ["error"], // console.errorはerror boundaryでキャッチできないので送信する
    sampleRate,
    silentMultipleInit: true,
    ...mustBeLogsAndRumSameConfigs,
  });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- browser sdkの型をインストールできないのでany
const datadogRumInit = (datadogRum: any): void => {
  if (
    !(env === "production" || env === "staging" || env === "qa") ||
    isFalsyString(clientToken) ||
    isFalsyString(applicationId)
  ) {
    return;
  }
  const sampleRate = env === "production" ? 100 : 0;
  // sessionReplayはお金がかかるので使わないようにする
  // https://docs.datadoghq.com/ja/real_user_monitoring/session_replay/#%E3%82%BB%E3%83%83%E3%82%B7%E3%83%A7%E3%83%B3%E3%83%AA%E3%83%97%E3%83%AC%E3%82%A4%E3%82%92%E7%84%A1%E5%8A%B9%E3%81%AB%E3%81%99%E3%82%8B
  const sessionReplaySampleRate = 0;

  // https://docs.datadoghq.com/ja/real_user_monitoring/browser/#%E5%88%9D%E6%9C%9F%E5%8C%96%E3%83%91%E3%83%A9%E3%83%A1%E3%83%BC%E3%82%BF%E3%83%BC
  datadogRum.init({
    applicationId,
    clientToken,
    site,
    service,
    env,
    version,
    allowedTracingOrigins: [baseURL as string],
    sampleRate,
    trackInteractions: true,
    trackFrustrations: true,
    enableExperimentalFeatures: ["clickmap"],
    sessionReplaySampleRate,
    defaultPrivacyLevel: "mask",
    silentMultipleInit: true,
    ...mustBeLogsAndRumSameConfigs,
  });

  // sessionReplayはお金がかかるので使わないようにする
  // datadogRum.startSessionReplayRecording();
};

/**
 * @NOTE next/scriptのScriptコンポーネントなので、next/headのHeadコンポーネントの外側に置く必要がある
 * @see  https://nextjs.org/docs/pages/api-reference/components/script#afterinteractive

 */
export const DatadogInit = () => {
  return (
    <>
      <Script
        async
        id="datadog-logs"
        strategy="afterInteractive"
        src={`https://www.datadoghq-browser-agent.com/${region}/${datadogVersion}/datadog-logs.js`}
        onReady={() => {
          window?.DD_LOGS?.onReady(() => {
            datadogLogsInit(window?.DD_LOGS);
          });
        }}
      />
      <Script
        async
        id="datadog-rums"
        strategy="afterInteractive"
        // https://docs.datadoghq.com/ja/real_user_monitoring/browser/
        src={`https://www.datadoghq-browser-agent.com/${region}/${datadogVersion}/datadog-rum.js`}
        onReady={() => {
          window?.DD_RUM?.onReady(() => {
            datadogRumInit(window?.DD_RUM);
          });
        }}
      />
    </>
  );
};

/**
 * CDNの読み込みが終わってから実行できるようにするため、callback内でdatadogLogs関数を使えるようにする
 */
export const ddLogs = (
  callback: (datadogLogs: typeof window.DD_LOGS) => void,
) => {
  window?.DD_LOGS?.onReady(() => {
    callback(window.DD_LOGS);
  });
};
/**
 * CDNの読み込みが終わってから実行できるようにするため、callback内でdatadogRum関数を使えるようにする
 */
export const ddRum = (callback: (datadogRum: typeof window.DD_RUM) => void) => {
  window?.DD_RUM?.onReady(() => {
    callback(window.DD_RUM);
  });
};
