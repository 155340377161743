/* eslint-disable react-hooks/rules-of-hooks */
import useSWR, { type SWRConfiguration } from "swr";
import { type MadrasAPIOptions } from "./type";
import { apiClient } from "@/api/client";
import { type SWRResponse } from "@/api/swr/types";
import {
  type ApiPathsWithGetJsonMethod,
  type ApiResponse,
  type Methods,
} from "@/api/types";
import { createKey } from "@/api/swr/helpers";
import { type ApiClientWrapperArgs } from "@/api/helpers/api-client-wrapper/types";
import { useRevalidate } from "@/api/swr/hooks";

/**
 * fetch ライブラリ(SWR) と api client との接続を責務とする。
 * ライブラリ由来の問題は当ファイルで解決する。
 * @note
 * [useAspidaSWR](https://github.com/aspida/aspida/tree/main/packages/aspida-swr) を利用していた経緯から、
 * そのカスタムフックの実装をベースに作成されているが、今後必要に応じて修正する。
 * @FIXME
 * useMadrasAPICore -> useAPICore と名前を変更し、 madras 依存の箇所を除外する。
 */
export function useMadrasAPICore<
  Path extends ApiPathsWithGetJsonMethod,
  Method extends Methods<Path> & "get",
  ForcePagerType extends boolean,
>(
  path: Path,
  options?: MadrasAPIOptions<Path, Method, ForcePagerType>,
): SWRResponse<ApiResponse<Path, Method>> {
  const {
    params,
    swrOptions,
    axiosRequestConfig,
    enabled,
    key: keyProp,
  } = options ?? {};
  const key = createKey<Path, Method>({
    path,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- FIXME：条件により型が決定する箇所であり、適切な型の付与が難しい
    params: params as any,
    enabled: enabled ?? true,
    key: keyProp,
  });

  async function fetcher() {
    const axiosResponse = await apiClient({
      path,
      method: "get" as Method,
      params,
      axiosRequestConfig,
    } as unknown as ApiClientWrapperArgs<Path, Method>);
    return axiosResponse.data;
  }

  const response = useSWR(key, fetcher, swrOptions as SWRConfiguration);

  useRevalidate({ response, key }, swrOptions);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- FIXME: madras 依存ではない apiClient を用意し、useMadrasAPICore では、それを用いる
  return response as any;
}
