import {
  type FC,
  type PropsWithChildren,
  createContext,
  useContext,
  useState,
  useMemo,
} from "react";
import { localStorageKeys } from "@/configs/localStorage";
import { localStorageAvailable } from "@/helper/local-storage";

const initialValue = false;
const _useMenuDisclosure = () => {
  const [isMenuOpened, setMenu] = useState(() => {
    if (!localStorageAvailable()) {
      return initialValue;
    }
    const boolString =
      localStorage.getItem(localStorageKeys.menu) ?? String(initialValue);
    return boolString === "true";
  });
  const toggleMenu = () => {
    const setItem = !isMenuOpened;
    if (localStorageAvailable()) {
      localStorage.setItem(localStorageKeys.menu, String(setItem));
    }
    setMenu((prev) => !prev);
  };
  return { isMenuOpened, toggleMenu } as const;
};

const MenuContext = createContext<ReturnType<typeof _useMenuDisclosure>>(
  {} as any,
);

export const useMenuDisclosure = () => {
  return useContext(MenuContext);
};

export const MenuToggleProvider: FC<PropsWithChildren> = ({ children }) => {
  const menu = _useMenuDisclosure();
  const memorizedMenu = useMemo(() => menu, [menu]);
  return (
    <MenuContext.Provider value={memorizedMenu}>
      {children}
    </MenuContext.Provider>
  );
};
