import { useEffect, useState } from "react";

const DEFAULT_DELAY_MS = 500;

export function useDebounce<T>(
  value: T,
  option?: { delay?: number; disable?: boolean },
): { debouncedValue: T; isDebouncing: boolean } {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);
  const [isDebouncing, setIsDebouncing] = useState(false);

  useEffect(() => {
    if (option?.disable === true) {
      setDebouncedValue(value);
      return () => {
        /* noop */
      };
    }
    setIsDebouncing(true);

    const timer = setTimeout(
      () => {
        setIsDebouncing(false);
        setDebouncedValue(value);
      },
      option?.delay || DEFAULT_DELAY_MS,
    );

    return () => {
      clearTimeout(timer);
    };
  }, [option?.delay, option?.disable, value]);

  return { debouncedValue, isDebouncing };
}
